var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"grey darken-3 white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Produkter")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.showSearch=!_vm.showSearch}}},[(!_vm.showSearch)?_c('v-icon',[_vm._v("mdi-magnify")]):_c('v-icon',[_vm._v("mdi-magnify-close")])],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.createProduct()}}},[_c('v-list-item-title',[_vm._v("Opret")])],1),(this.selectedProducts.length > 0)?_c('v-list-item',{on:{"click":function($event){return _vm.deleteProducts()}}},[_c('v-list-item-title',[_vm._v("Slet")])],1):_vm._e()],1)],1)],1),_c('v-card-text',[(_vm.showSearch)?_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","hide-details":"auto","label":"Søg","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.productDataservice.products,"item-key":"id","dense":"","search":_vm.search,"items-per-page":10,"show-select":""},scopedSlots:_vm._u([{key:"item.primaryImage",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.getDownloadUrlOfPrimaryImage(item),"width":"90px","height":"90px"},on:{"click":function($event){return _vm.editItem(item)}}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"big":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}}],null,true),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }