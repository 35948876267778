







































































import Vue from 'vue';
import ConfirmDialog from '../components/ConfirmDialog.vue';
import { Product } from '../../../shared/src/entities/Product';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';

export default Vue.extend({
  inject: {
    productDataservice: 'productDataservice'
  },
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      headers: [
          { text: 'Billede', value: 'primaryImage', sortable: false, filterable: false },
          { text: 'Navn', value: 'name' },
          { text: 'Status', value: 'status.name' },
          { text: 'Kategori', value: 'category.name' },
          { text: 'Oprettet', value: 'createdTimeLocalized' },
          { text: 'Id', value: 'id' },
          { text: 'Rediger', value: 'action', sortable: false, filterable: false }
        ],
        search: '',
        selectedProducts: [] as Product[],
        showSearch: false,
        deleteProductsVisible: false
      };
    },
    methods: {
      editItem(product: Product): void {
        this.$router.push({ name: 'Product', params: { id: product.id } });
      },
      getDownloadUrlOfPrimaryImage(product: Product): string | undefined {
        if (!product) {
          return undefined;
        }

        if (!product.primaryImage) {
          return undefined;
        }

        return product.primaryImage.smallImagedownloadUrl;
      },
      async createProduct(): Promise<void> {
        const productDataservice = ((this as any).productDataservice as IProductDataservice);
        const productId = await productDataservice.addProduct();
        this.$router.push({ name: 'Product', params: { id: productId } });
      },
      deleteProducts(): void {
        (this.$refs.confirmDialog as any).show('Er du sikker på at du vil slette ' +
          this.selectedProducts.length + ' produkter',
          this.acceptDeleteProducts);
      },
      acceptDeleteProducts(): void {
          const deleteProducts: Array<Promise<any>> = [];
          this.selectedProducts.forEach((product) => {
            deleteProducts.push(((this as any).productDataservice as IProductDataservice).deleteProduct(product.id));
          });
          Promise.all( deleteProducts ).then(() => {
            this.selectedProducts = [];
        });
      }
    }
});
